export interface UserResponse {
  id: number;
  nodeId: number;
  username: string;
  email: string;
  forename: string;
  surname: string;
  adminUserRoleId: number;
}

export class User {
  id: number;
  nodeId: number;
  username: string;
  email: string;
  forename: string;
  surname: string;
  adminUserRoleId: number;
  defaultPrefix: string = '';

  public static parseJSON(json: string): User | null {
    if (!json) return null;
    return this.parse(JSON.parse(json));
  }

  public static parseJSONList(json: string): User[] {
    if (!json) return [];
    return this.parseList(JSON.parse(json));
  }

  public static parse(data: any): User {
    return new User(
      data.id,
      data.nodeId,
      data.username,
      data.email,
      data.forename,
      data.surname,
      data.adminUserRoleId
    );
  }

  public static parseList(data: any): User[] {
    let list = [];

    if (data && data instanceof Array) {
      for (let obj of data) {
        list.push(User.parse(obj))
      }
    }

    return list;
  }

  public static create(response: UserResponse): User {
    return User.parse(response);
  }

  public static createList(response: UserResponse[]): User[] {
    return User.parseList(response);
  }

  get fullName(): string {
    return this.forename + ' ' + this.surname;
  }

  public static new(): User {
    return new User(0, 0, '', '', '', '', 0);
  }

  constructor(id: number,
              nodeId: number,
              username: string,
              email: string,
              forename: string,
              surname: string,
              adminUserRoleId: number) {
    this.id = id;
    this.nodeId = nodeId;
    this.username = username;
    this.email = email;
    this.forename = forename;
    this.surname = surname;
    this.surname = surname;
    this.adminUserRoleId = adminUserRoleId;
  }

}

export interface UserData {
  username: string,
  forename: string,
  surname: string,
  email: string,
  password: string,
  isAdmin: boolean,
  clientsIds: Array<number>
}