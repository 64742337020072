import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Form, Modal, Table} from "react-bootstrap";

import './RefundDetail.css';
import {NotificationContext} from "../../../contexts/Notification";
import {RefundData} from "../../../models/RefundModel";
import {transactionService} from "../../../services/TransactionService";
import {useLocation, useNavigate} from "react-router-dom";
import {NumericFormat} from "react-number-format";

export const RefundDetail = () => {
    let navigate = useNavigate();
    const {state} = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
    const notificationCtx = useContext(NotificationContext);
    const [show, setShow] = useState(false);
    const [disableRefundButton, setDisableRefundButton] = useState(false);
    const [refundInformation, setRefundInformation] = useState('');
    const [refundAmount, setRefundAmount] = useState(state?.pcnDetails.amount);
    const [allRefunds, setAllRefunds] = useState<any>([]);
    const [currentRefunds, setCurrentRefunds] = useState<any[]>([]);
    const [showRefunds, setShowRefunds] = useState(false);
    const [pcnData, setPcnData] = useState<any>({
        transactionId: state?.pcnDetails.transactionId,
        pcnNumber: state?.pcnDetails.pcnNumber,
        vrm: state?.pcnDetails.vrm,
        amount: state?.pcnDetails.amount,
        receiptId: state?.pcnDetails.receiptId,
        transactionRef: state?.pcnDetails.transactionRef,
        transactionDateTime: state?.pcnDetails.transactionDateTime
    });
    const [filterInputs, setFilterInputs] = useState<RefundData>(state?.filterInputs);

    function searchRefunds() {
        setCurrentRefunds(() => ([[], []]));
        setAllRefunds(() => ([[], []]));
        setShowRefunds(false);
        setErrorMessage('');

        transactionService.searchRefund(state?.pcnDetails.transactionId).then((response: any) => {
            if (response.data) {
                if (response.data.length > 0) {
                    setShowRefunds(true);
                    setCurrentRefunds(prev => {
                        return response.data;
                    });
                    checkIfRefundable(response);
                }
            } else {
                setErrorMessage(response.message);
                console.log("response.message", response.message);
            }
        }).catch((e) => {
            console.error(e);
            notificationCtx?.error('Network error. Check internet connection or try again later.')
        });
    }

    function formatAmountProperly(amount: number) {
        return new Intl.NumberFormat("en", {
            minimumFractionDigits: 2,
        }).format(amount / 100)
    }

    useEffect(() => {
        searchRefunds();
    }, []);

    useEffect(() => {
        for (let i = 0; i < currentRefunds.length; i++) {
            if (currentRefunds[i].id) {
                allRefunds.push(
                    <tr key={i} style={{verticalAlign: "middle"}}>
                        <td className="list-header">{currentRefunds[i].id}</td>
                        <td className="list-header">{currentRefunds[i].receiptId}</td>
                        <td className="list-header">{currentRefunds[i].refundDateTime}</td>
                        <td className="list-header">{currentRefunds[i].adminName}</td>
                        <td className="list-header">{currentRefunds[i].transactionId}</td>
                        <td className="list-header">{formatAmountProperly(currentRefunds[i].amount)}</td>
                    </tr>
                )
            }
        }
        setAllRefunds((prevAllUser: any) => ([[], allRefunds]));
    }, [currentRefunds]);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setErrorMessage('');
        setDisableRefundButton(true);
        transactionService.refundTransaction(pcnData.transactionId, refundAmount).then((response: any) => {
            if (response.data) {
                notificationCtx?.success("PCN: " + pcnData.pcnNumber + " refunded successfully, receipt: " + response.data.refund.receiptId);
                searchRefunds();
                setDisableRefundButton(false);
                setShow(false);
            } else {
                setErrorMessage(response.message);
                setShow(false);
                console.log("response.message", response.message);
                setDisableRefundButton(false);
            }
        }).catch((e) => {
            console.error(e);
            notificationCtx?.error('Network error. Check internet connection or try again later.');
            setShow(false);
            setDisableRefundButton(false);
        });
    }

    function checkIfRefundable(response: any) {
        let sum = 0;
        response.data.forEach((refund: any) => {
            sum = sum + Number(refund.amount)
        });
        if (sum >= state?.pcnDetails.amount) {
            setDisableRefundButton(true);
            setErrorMessage('The total amount for this transaction has been refunded');
        } else {
            setDisableRefundButton(false);
            setErrorMessage('');
        }
    }

    return (
        <div className="refund-wrapper">
            <Card className="refund-main-card">
                <h1 className="refund-main-title">
                    <p>Refund PCN</p>
                </h1>
                <hr className="refund-block-separator"/>
                <Card.Body>
                    <div>
                        <Table responsive="sm" striped bordered hover size="sm"
                               className='p-1 list-table-self'>
                            <thead>
                            <tr>
                                <th className="list-header">Transaction ID</th>
                                <th className="list-header">PCN Number</th>
                                <th className="list-header">VRM</th>
                                <th className="list-header">Amount</th>
                                <th className="list-header">Receipt ID</th>
                                <th className="list-header">Transaction Reference</th>
                                <th className="list-header">Transaction Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="list-header">{pcnData.transactionId}</td>
                                <td className="list-header">{pcnData.pcnNumber}</td>
                                <td className="list-header">{pcnData.vrm}</td>
                                <td className="list-header">{formatAmountProperly(pcnData.amount)}</td>
                                <td className="list-header">{pcnData.receiptId}</td>
                                <td className="list-header">{pcnData.transactionRef}</td>
                                <td className="list-header">{pcnData.transactionDateTime}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                    <Button style={{marginRight: 10}} onClick={() => navigate('/refundPCN', {
                        state: {
                            filterInputs: filterInputs
                        }
                    })}
                            className="btn btn-primary">Back to PCN Search</Button>
                    <Button disabled={disableRefundButton} onClick={handleShow} className="btn btn-primary">Refund
                        PCN</Button>

                    {showRefunds &&
                        <div style={{marginTop: 20}}>
                            <Table responsive="sm" striped bordered hover size="sm"
                                   className='p-1 list-table-self'>
                                <thead>
                                <tr>
                                    <th className="list-header">ID</th>
                                    <th className="list-header">Receipt ID</th>
                                    <th className="list-header">Refund Date</th>
                                    <th className="list-header">Refunded By</th>
                                    <th className="list-header">Transaction ID</th>
                                    <th className="list-header">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {allRefunds}
                                </tbody>
                            </Table>
                        </div>
                    }
                </Card.Body>
                <p className="refund-error-message">{errorMessage}</p>
            </Card>

            <Modal show={show && !disableRefundButton} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Refund PCN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please confirm to process this refund amount to PCN: {pcnData.pcnNumber}
                    <Form style={{padding: 15}}>
                        <NumericFormat value={refundAmount / 100} fixedDecimalScale decimalScale={2}
                                       onChange={(e: { target: { value: any; }; }) => setRefundAmount(Number(e.target.value) * 100)}/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {!disableRefundButton && 'Cancel'}
                        {disableRefundButton && 'Close'}
                    </Button>
                    <Button disabled={disableRefundButton} variant="primary" onClick={handleSubmit}>
                        Process Refund
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
