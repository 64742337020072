import {apiService} from "./ApiService";
import {LoginResponse} from "../models/Responses";


const runReport = async (inputs: any,path:string): Promise<any | undefined> => {
    const request: any = {
        dateFrom: inputs.dateFrom.prepare(),
        dateTo: inputs.dateTo.prepare(),
        pcn: inputs.pcn,
        vrm: inputs.vrm,
        receiptId: inputs.receiptId,
        lastDigits: inputs.lastDigits,
        clientsIds: [],
        successList: inputs.successList
    };

    if (inputs.clientsIds){
        for (let i = 0; i < inputs.clientsIds.length; i++) {
            request.clientsIds.push(inputs.clientsIds[i].value);
        }
    }
    return await apiService.run<LoginResponse>(path, request);
}

export const reportService = {
    runReport
}
