import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Form, Table} from "react-bootstrap";

import './Refund.css';
import {NotificationContext} from "../../contexts/Notification";
import {RefundData} from "../../models/RefundModel";
import {CcpDateTime} from "../../models/ccp-date-time";
import {transactionService} from "../../services/TransactionService";
import {useLocation, useNavigate} from "react-router-dom";

export const Refund = () => {

    let navigate = useNavigate();
    const {state} = useLocation();
    const [inputs, setInputs] = useState<RefundData>({
        receiptId: '',
        vrm: '',
        pcnNumber: '',
        startDate: state ? CcpDateTime.fromString(CcpDateTime.toYYYYMMDD((state.filterInputs.startDate))) : CcpDateTime.startOfMonth(),
        endDate: state ? CcpDateTime.fromString(CcpDateTime.toYYYYMMDD((state.filterInputs.endDate))) : CcpDateTime.now()
    });
    const [allTransactions, setAllTransactions] = useState<any>([]);
    const [currentTransactions, setCurrentTransactions] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const notificationCtx = useContext(NotificationContext);

    function loadFilters() {
        handleChange({target: {name: 'pcnNumber', value: state.filterInputs.pcnNumber}});
        handleChange({target: {name: 'vrm', value: state.filterInputs.vrm}});
        handleChange({target: {name: 'receiptId', value: state.filterInputs.receiptId}});

        dateHoursChange({target: {name: 'startDate', value: state.filterInputs.startDate.time.hour}});
        dateMinChange({target: {name: 'startDate', value: state.filterInputs.startDate.time.minute}});
        dateSecsChange({target: {name: 'startDate', value: state.filterInputs.startDate.time.second}});

        dateHoursChange({target: {name: 'endDate', value: state.filterInputs.endDate.time.hour}});
        dateMinChange({target: {name: 'endDate', value: state.filterInputs.endDate.time.minute}});
        dateSecsChange({target: {name: 'endDate', value: state.filterInputs.endDate.time.second}});
        setFiltersLoaded(true);
    }
    function formatAmountProperly (amount: number) {
        return new Intl.NumberFormat("en", {
            minimumFractionDigits: 2,
        }).format(amount/100)
    }

    useEffect(() => {
        if (state) {
            loadFilters();
        }
    }, []);

    useEffect(() => {
        if(filtersLoaded && formValid) {
            searchTransactions();
        }
    }, [filtersLoaded, formValid]);

    useEffect(() => {
        setFormValid(() => {
            return inputs.pcnNumber.length > 0 || inputs.vrm.length > 0 || inputs.receiptId.length > 0;
        });
    }, [inputs]);

    useEffect(() => {
        for (let i = 0; i < currentTransactions.length; i++) {
            if (currentTransactions[i].transactionId) {
                allTransactions.push(
                    <tr key={i} style={{verticalAlign: "middle"}}>
                        <td className="list-header">{currentTransactions[i].transactionId}</td>
                        <td className="list-header">{currentTransactions[i].pcnNumber}</td>
                        <td className="list-header">{currentTransactions[i].vrm}</td>
                        <td className="list-header">{formatAmountProperly(currentTransactions[i].amount)}</td>
                        <td className="list-header">{currentTransactions[i].receiptId}</td>
                        <td className="list-header">{currentTransactions[i].transactionRef}</td>
                        <td className="list-header">{currentTransactions[i].transactionDateTime}</td>
                        <td className="list-header">{currentTransactions[i].state}</td>
                        <td className="list-header">
                            <Button onClick={() => navigate('/refundDetail', {
                                state: {
                                    pcnDetails: currentTransactions[i],
                                    filterInputs: inputs
                                }
                            })}
                                    className="btn btn-primary">Refund</Button>
                        </td>
                    </tr>
                )
            }
        }
        setAllTransactions((prevAllUser: any) => ([[], allTransactions]));
    }, [currentTransactions]);

    function searchTransactions() {
        setCurrentTransactions(() => ([[], []]));
        setAllTransactions(() => ([[], []]));
        setShowResults(false);
        setErrorMessage('');

        transactionService.searchTransactions(inputs).then((response: any) => {
            if (response.data) {
                if (response.data.length > 0) {
                    setShowResults(true);
                    setCurrentTransactions(prev => {
                        return response.data;
                    });
                } else {
                    setErrorMessage(response.message);
                }
            } else {
                setErrorMessage(response.message);
                console.log("response.message", response.message);
            }
        }).catch((e) => {
            console.error(e);
            notificationCtx?.error('Network error. Check internet connection or try again later.')
        });
    }

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        searchTransactions();
    }
    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const value = event.target.value.trim();
        setInputs(values => ({...values, [name]: value}))
    }

    const startDateChange = (event: { target: { name: any; value: any; }; }) => {
        const name = 'startDate';
        let value = event.target.value;
        let newDate: CcpDateTime = CcpDateTime.fromString(value);

        newDate.setTimeStruct(inputs.startDate.getTime());
        setInputs(values => ({...values, [name]: newDate}))
    }

    const endDateChange = (event: { target: { name: any; value: any; }; }) => {
        const name = 'endDate';
        let value = event.target.value;
        let newDate: CcpDateTime = CcpDateTime.fromString(value);

        newDate.setTimeStruct(inputs.endDate.getTime());
        setInputs(values => ({...values, [name]: newDate}))
    }

    const dateHoursChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        let value = Number(event.target.value);
        if ((value >= 0) && (value <= 23)) {
            if (name === "startDate") {
                setInputs(values => ({...values, [name]: inputs.startDate.setHour(value)}));
            } else {
                setInputs(values => ({...values, [name]: inputs.endDate.setHour(value)}));
            }
        }
    }

    const dateMinChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        let value = event.target.value;
        if ((value >= 0) && (value <= 59)) {
            if (name === "startDate") {
                setInputs(values => ({...values, [name]: inputs.startDate.setMin(value)}));
            } else {
                setInputs(values => ({...values, [name]: inputs.endDate.setMin(value)}));
            }
        }
    }

    const dateSecsChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        let value = event.target.value;
        if ((value >= 0) && (value <= 59)) {
            if (name === "startDate") {
                setInputs(values => ({...values, [name]: inputs.startDate.setSec(value)}));
            } else {
                setInputs(values => ({...values, [name]: inputs.endDate.setSec(value)}));
            }
        }
    }

    const startOfToday = (type: string) => {
        setInputs(values => ({...values, [type]: CcpDateTime.startOfToday()}))
    }

    const endOfToday = (type: string) => {
        setInputs(values => ({...values, [type]: CcpDateTime.endOfToday()}))
    }

    const startOfMonth = (type: string) => {
        setInputs(values => ({...values, [type]: CcpDateTime.startOfMonth()}))
    }

    const startOfLastMonth = (type: string) => {
        setInputs(values => ({...values, [type]: CcpDateTime.startOfLastMonth()}))
    }

    const endOfLastMonth = (type: string) => {
        setInputs(values => ({...values, [type]: CcpDateTime.endOfLastMonth()}))
    }

    return (
        <div className="refund-wrapper">
            <Card className="refund-main-card">
                <h1 className="refund-main-title">
                    <p>Refund PCN</p>
                </h1>
                <hr className="refund-block-separator"/>
                <Card.Body>
                    <Form>
                        <div className='p-1 refund-form-wrapper'>
                            <div className='refund-form-div'>
                                <div className='p-2'>
                                    <Form.Label>PCN Number*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="pcnNumber"
                                        placeholder={"PCN Number"}
                                        value={inputs.pcnNumber}
                                        onChange={handleChange}/>
                                </div>
                                <div className='p-2'>
                                    <Form.Label>VRM*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vrm"
                                        placeholder={"VRM"}
                                        value={inputs.vrm}
                                        onChange={handleChange}/>
                                </div>
                                <div className='p-2'>
                                    <Form.Label>Receipt ID*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="receiptId"
                                        placeholder={"Receipt ID"}
                                        value={inputs.receiptId}
                                        onChange={handleChange}/>
                                </div>
                            </div>
                            <div className='refund-form-div-date-wrapper py-2'>
                                <div>
                                    <div className='ps-2'>
                                        <Form.Label>Start Date</Form.Label>
                                    </div>
                                    <div className='refund-form-div-date ms-2'>
                                        <div className='flex-item'>
                                            <Form.Control
                                                type="date"
                                                name="startDate"
                                                value={CcpDateTime.toYYYYMMDD(inputs.startDate)}
                                                onChange={startDateChange}/>
                                        </div>
                                        <div className="flex-item">
                                            <Form.Control
                                                type="number"
                                                htmlSize={2}
                                                name="startDate"
                                                placeholder={"Date From Hours"}
                                                value={inputs.startDate.getTime().hour}
                                                onChange={dateHoursChange}/>
                                        </div>
                                        <div style={{alignSelf: "center"}}>
                                            :
                                        </div>
                                        <div className="flex-item">
                                            <Form.Control
                                                type="number"
                                                htmlSize={2}
                                                name="startDate"
                                                placeholder={"Date From Hours"}
                                                value={inputs.startDate.getTime().minute}
                                                onChange={dateMinChange}/>

                                        </div>
                                        <div style={{alignSelf: "center"}}>
                                            :
                                        </div>
                                        <div className="flex-item">
                                            <Form.Control
                                                type="number"
                                                htmlSize={2}
                                                name="startDate"
                                                placeholder={"Date From Seconds"}
                                                value={inputs.startDate.getTime().second}
                                                onChange={dateSecsChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div key={23} className="flex-container-buttons ps-2">
                                        <div className="flex-item">
                                            <Button variant="outline-primary" onClick={() => startOfToday("startDate")}>Start
                                                of
                                                Today</Button>
                                        </div>
                                        <div key={24} className="flex-item">
                                            <Button variant="outline-primary" onClick={() => endOfToday("startDate")}>End
                                                of
                                                Today</Button>
                                        </div>
                                        <div key={25} className="flex-item">
                                            <Button variant="outline-primary" onClick={() => startOfMonth("startDate")}>Start
                                                of
                                                this Month</Button>
                                        </div>
                                        <div key={26} className="flex-item">
                                            <Button variant="outline-primary"
                                                    onClick={() => startOfLastMonth("startDate")}>Start
                                                Last Month</Button>
                                        </div>
                                        <div key={27} className="flex-item">
                                            <Button variant="outline-primary"
                                                    onClick={() => endOfLastMonth("startDate")}>End of
                                                Last Month</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='refund-form-div-date-wrapper py-2'>
                                <div>
                                    <div className='ps-2'>
                                        <Form.Label>End Date</Form.Label>
                                    </div>
                                    <div className='refund-form-div-date ms-2'>
                                        <div className='flex-item'>
                                            <Form.Control
                                                type="date"
                                                name="endDate"
                                                value={CcpDateTime.toYYYYMMDD(inputs.endDate)}
                                                onChange={endDateChange}/>
                                        </div>
                                        <div className="flex-item">
                                            <Form.Control
                                                type="number"
                                                htmlSize={2}
                                                name="endDate"
                                                placeholder={"Date From Hours"}
                                                value={inputs.endDate.getTime().hour}
                                                onChange={dateHoursChange}/>
                                        </div>
                                        <div style={{alignSelf: "center"}}>
                                            :
                                        </div>
                                        <div className="flex-item">
                                            <Form.Control
                                                type="number"
                                                htmlSize={2}
                                                name="endDate"
                                                placeholder={"Date From Hours"}
                                                value={inputs.endDate.getTime().minute}
                                                onChange={dateMinChange}/>

                                        </div>
                                        <div style={{alignSelf: "center"}}>
                                            :
                                        </div>
                                        <div className="flex-item">
                                            <Form.Control
                                                type="number"
                                                htmlSize={2}
                                                name="endDate"
                                                placeholder={"Date From Seconds"}
                                                value={inputs.endDate.getTime().second}
                                                onChange={dateSecsChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div key={23} className="flex-container-buttons ps-2">
                                        <div className="flex-item">
                                            <Button variant="outline-primary" onClick={() => startOfToday("endDate")}>Start
                                                of
                                                Today</Button>
                                        </div>
                                        <div key={24} className="flex-item">
                                            <Button variant="outline-primary" onClick={() => endOfToday("endDate")}>End
                                                of
                                                Today</Button>
                                        </div>
                                        <div key={25} className="flex-item">
                                            <Button variant="outline-primary" onClick={() => startOfMonth("endDate")}>Start
                                                of
                                                this Month</Button>
                                        </div>
                                        <div key={26} className="flex-item">
                                            <Button variant="outline-primary"
                                                    onClick={() => startOfLastMonth("endDate")}>Start
                                                Last Month</Button>
                                        </div>
                                        <div key={27} className="flex-item">
                                            <Button variant="outline-primary" onClick={() => endOfLastMonth("endDate")}>End
                                                of
                                                Last Month</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="mandatory-message">*At least one field required.</p>
                        <hr className="refund-bottom-block-separator"/>
                        <Button disabled={!formValid} onClick={handleSubmit} className="btn btn-primary">Find
                            PCN</Button>
                    </Form>
                    {showResults &&
                        <div>
                            <hr style={{width: '100%', alignSelf: 'center'}}/>
                            <div>
                                <Table responsive="sm" striped bordered hover size="sm"
                                       className='p-1 list-table-self'>
                                    <thead>
                                    <tr>
                                        <th className="list-header">ID</th>
                                        <th className="list-header">PCN</th>
                                        <th className="list-header">VRM</th>
                                        <th className="list-header">Amount</th>
                                        <th className="list-header">Receipt ID</th>
                                        <th className="list-header">Transaction Reference</th>
                                        <th className="list-header">Transaction Date</th>
                                        <th className="list-header">State</th>
                                        <th className="list-header"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {allTransactions}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    }
                </Card.Body>
                <p className="refund-error-message">{errorMessage}</p>
            </Card>
        </div>
    );
}