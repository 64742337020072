import {authService} from "./AuthService";

const run = async <T>(endpoint: string, data?: any, options?: ApiOptions): Promise<T | undefined> => {

    const apiUrl: string = process.env.REACT_APP_API_URL + endpoint;
    const headers: Headers = new Headers();
    if (!options || !options.isFileUpload) {
        headers.append('Content-Type', 'application/json');
    }

    const authRequired: boolean = !options || !options.noAuthRequired;

    if (authRequired) {
        let authToken: string | undefined | null = options ? options.specificAuthToken : null;
        if (!authToken) {
            if (!authService.isLoggedIn) {
                throw Error('Not logged in or no Auth Token supplied');
            }
            authToken = authService.getAuthToken();
            if (!authToken){
                authToken = authService.getAuthTokenFromStorage();
            }
        }
        headers.append('Authorization', 'Bearer ' + authToken);
    }

    if (options && options.extraTimeout) {
        const time: number = 15 * 60 * 1000;
        headers.append('timeout', time.toString());
    }


    if (options && options.isFileUpload) {
        const formData = new FormData();
        formData.append('file', data);
        data = formData;
    }

    try {
        const response: Response = await runApiCall<T>(apiUrl, data, headers);
        return handleApiResponse(response);
    } catch (e) {
        throw e;
    }

}

const runApiCall = async <T>(apiUrl: string, data: any, headers: Headers): Promise<Response> => {
    let response: Response;
    let body = data ? JSON.stringify(data) : null;
    response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: body
    }).catch((e) => {
        console.error('Error making API request: ' + e.message || e);
        throw new Error('Error making API request');
    });
    console.log('API Response: [' + apiUrl + ']');
    return response;
};

const handleApiResponse = async <T>(response: Response): Promise<any> => {
    if (!response.ok) {
        console.error('Response from API was not ok (not a 2xx)');
        throw new Error('Response from API was not ok (not a 2xx)');
    }
    const baseResponse: BaseResponse<T> = await response.json();

    if (baseResponse == null) {
        console.error('Response from API was null');
        throw new Error('Response from API was null');
    }

    if (baseResponse.errorCode != null) {
        console.error('API Error Code: [' + baseResponse.errorCode + ']');
        //TODO change the way the error codes are handled, will be directly from the backend, so no need to parse them.
        if (baseResponse.errorCode >= 6000 && baseResponse.errorCode < 7000) {
            throw new Error(`${baseResponse.message} (System Reference: ${baseResponse.errorCode})`);
        } else {
            throw new Error(`${baseResponse.message} (System Reference: ${baseResponse.errorCode})`);
        }
    }
    return baseResponse;
}

export interface ApiOptions {
    noAuthRequired?: boolean;
    specificAuthToken?: string;
    extraTimeout?: boolean;
    isFileUpload?: boolean;
}

export interface BaseResponse<T> {
    data: T;
    errorCode: number;
    errors: Array<string>;
    message: string;
}

export interface IdRequest {
    id: number
}

export const apiService = {
    run
};
