import React, {useEffect} from "react";
import {authService} from "../services/AuthService";
import {Navigate, useLocation} from "react-router-dom";
import {userService} from "../services/UserService";

interface AuthContextType {
    user: any;
    signin: (user: string, password: string, callback: VoidFunction) => Promise<string>;
    signout: (callback: VoidFunction) => void;
    relogin: (token: string) => Promise<string>;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({children}: { children: React.ReactNode }) {
    let [user, setUser] = React.useState<any>(null);

    useEffect(() => {
        let token = authService.getAuthTokenFromStorage();
        if (token) {
            relogin(token);
        }
    }, []);

    let signin = (user: string, password: string) => {
        return userService.login(user, password).then((response: any) => {
            if(response.message){
                return response.message;
            } else {
                authService.setAuthTokenToStorage(response.jwt);
                setUser(response.user);
                return 'ok';
            }
        });
    };

    let relogin = (token: string) => {
        return userService.reLogin(token).then((response: any) => {
            if(response.message){
                return response.message;
            } else {
                authService.setAuthTokenToStorage(response.jwt);
                setUser(response.user);
                return 'ok';
            }
        });
    };

    let signout = (callback: VoidFunction) => {
        authService.removeAuthTokenFromStorage();
        callback();
    };

    let value = {user, signin, signout, relogin};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export function RequireAuth({children}: { children: JSX.Element }) {
    let location = useLocation();
    if (!authService.getAuthTokenFromStorage()) {
        return <Navigate to="/" state={{from: location}} replace/>;
    }
    return children;
}