import React, {useContext, useEffect, useState} from "react";
import {Card, Form, Table} from "react-bootstrap";
import {userService} from "../../../services/UserService";
import {useNavigate} from "react-router-dom";
import './List.css';
import {NotificationContext} from "../../../contexts/Notification";

export const List = (props: { clientsIds?: any }) => {
    const [allUsers, setAllUsers] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [filters, setFilters] = useState<any>({
        id: "",
        username: "",
        forename: "",
        surname: ""
    });
    const [currentUsers, setCurrentUsers] = useState<any[]>([]);
    const [storedUsers, setStoredUsers] = useState<any[]>([]);
    const notificationCtx = useContext(NotificationContext);
    let navigate = useNavigate();


    useEffect(() => {
        userService.getAllUsers().then((response: any) => {
            if (response.data) {
                setCurrentUsers(prev => {
                    return response.data;
                });
                setStoredUsers(prev => {
                    return response.data;
                });
            } else {
                setErrorMessage(response.message);
            }
        }).catch((e) => {
            console.error(e);
            notificationCtx?.error('Network error. Check internet connection or try again later.')
        });
    }, []);

    useEffect(() => {
        for (let i = 0; i < currentUsers.length; i++) {
            allUsers.push(
                <tr key={i}>
                    <td>{currentUsers[i].id}</td>
                    <td>{currentUsers[i].username}</td>
                    <td>{currentUsers[i].forename}</td>
                    <td>{currentUsers[i].surname}</td>
                    <td>{currentUsers[i].email}</td>
                    <td className="list-table-data">
                        <i
                            onClick={() => navigate('/add', {
                                state: {
                                    user: currentUsers[i],
                                    permission: true
                                }
                            })}
                            className="bi bi-pencil-fill ms-1 list-section-item"></i>
                        <i
                            onClick={() => deleteUser(currentUsers[i].id, i, setAllUsers, currentUsers, setCurrentUsers, setErrorMessage, notificationCtx)}
                            className="bi bi-trash ms-1 list-section-item"></i></td>
                </tr>
            )
        }
        setAllUsers((prevAllUser: any) => ([[], allUsers]));
    }, [currentUsers]);

    useEffect(() => {

        let temp: any[] = [];
        if (filters.id === "" && filters.username === "" && filters.forename === "" && filters.surname === "") {
            setCurrentUsers(prev => {
                return storedUsers;
            });
            setAllUsers(() => {
                return [];
            });
        } else {
            temp = storedUsers.
            filter((item: any) => filters.id ? item.id?.toString().includes(filters.id) : true).
            filter((item: any) => filters.username ? item.username?.toLowerCase().includes(filters.username.toLowerCase()) : true).
            filter((item: any) => filters.forename ? (item.forename ? item.forename.toLowerCase().includes(filters.forename.toLowerCase()) : false) : true).
            filter((item: any) => filters.surname ? (item.surname ? item.surname.toLowerCase().includes(filters.surname.toLowerCase()) : false) : true);

            setCurrentUsers(prev => {
                return temp;
            });
            setAllUsers(() => {
                return [];
            });
        }
    }, [filters]);

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const value = event.target.value;
        setFilters((values: any) => ({...values, [name]: value}));
    };


    return (
        <div className="list-wrapper">

            <Card className="list-main-card">
                <h1 className="list-main-title">
                    List Users
                </h1>
                <hr className="list-block-separator"/>
                <Card.Body>
                    <div className='p-1 list-filter-wrapper'>
                        <div>
                            <Form.Label>User ID</Form.Label>
                            <Form.Control
                                type="text"
                                name="id"
                                placeholder={"User ID"}
                                value={filters.id}
                                onChange={handleChange}/>
                        </div>
                        <div>
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                placeholder={"User Name"}
                                value={filters.username}
                                onChange={handleChange}/>
                        </div>
                        <div>
                            <Form.Label>User Forename</Form.Label>
                            <Form.Control
                                type="text"
                                name="forename"
                                placeholder={"User Forename"}
                                value={filters.forename}
                                onChange={handleChange}/>
                        </div>
                        <div>
                            <Form.Label>User Surname</Form.Label>
                            <Form.Control
                                type="text"
                                name="surname"
                                placeholder={"User Surname"}
                                value={filters.surname}
                                onChange={handleChange}/>
                        </div>
                    </div>
                    <hr style={{width: '100%', alignSelf: 'center'}}/>
                    <div>
                        <Table responsive="sm" striped bordered hover size="sm" className='p-1 list-table-self'>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>User Name</th>
                                <th>Forename</th>
                                <th>Surname</th>
                                <th>Email</th>
                                <th className="list-header"><i
                                    onClick={() => navigate('/add', {state: {user: null, permission: true}})}
                                    className="bi bi-person-fill-add ms-1 list-section-item"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            {allUsers}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
                <p className="list-error-message">{errorMessage}</p>
            </Card>
        </div>
    );
}

export function deleteUser(id: number, indexId: number, setAllUsers: any, currentUsers: any = [], setCurrentUsers: any, setErrorMessage: any, notificationCtx: any) {
    userService.deleteUser(id).then((response: any) => {
        if (response.data) {
            let temp = currentUsers.filter((val: any[]) => val != currentUsers[indexId]);
            setAllUsers(() => {
                return [];
            });
            setCurrentUsers(() => {
                return temp;
            });
        } else {
            setErrorMessage(response.message);
            console.log("response.message", response.message);
        }
    }).catch((e) => {
        console.error(e);
        notificationCtx?.error('Network error. Check internet connection or try again later.')
    });
}
