import React from 'react';
import './App.css';
import {Login} from "./pages/Login/Login";
import {Outlet, Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home/Home";
import {AuthProvider, RequireAuth} from "./contexts/Auth";
import {NavbarComponent} from "./components/Navbar/Navbar";
import {List} from "./pages/User/List/List";
import {Add} from "./pages/User/Add/Add";
import {Report} from "./pages/Report/Report";
import NotificationBar from "./components/Notification/NotificationBar";
import NotificationProvider, {NotificationContext} from "./contexts/Notification";
import {Refund} from "./pages/Refund/Refund";
import {RefundDetail} from "./pages/Refund/RefundDetail/RefundDetail";

function App() {

    return (
            <NotificationProvider>
                <div className="App">
                    <NotificationBar/>
                        <AuthProvider>
                            <Routes>
                                <Route path="/" element={<Login/>}/>
                                <Route element={<Layout/>}>
                                    <Route path="/home" element={<RequireAuth><Home/></RequireAuth>}/>
                                    <Route path="/list" element={<RequireAuth><List/></RequireAuth>}/>
                                    <Route path="/add" element={<RequireAuth><Add/></RequireAuth>}/>
                                    <Route path="/viewPCN" element={<RequireAuth><Report tittle="View PCN" path="/report/transaction/viewPcnTransactions" pcnFilter={true} vrmFilter={true} purchaseOps={true} lastDigitsFilter={true} searchByReceiptId={true} tabReport={true}/></RequireAuth>}/>
                                    <Route path="/pcnAnalysisByDate" element={<RequireAuth><Report tittle="PCN Analysis by Date" path="/report/transaction/analysisByDate" pcnFilter={false} vrmFilter={false} purchaseOps={false} lastDigitsFilter={false}/></RequireAuth>}/>
                                    <Route path="/analysisByClient" element={<RequireAuth><Report tittle="PCN Analysis by Client" path="/report/transaction/analysisByClient" pcnFilter={false} vrmFilter={false} purchaseOps={false} lastDigitsFilter={false}/></RequireAuth>}/>
                                    <Route path="/analysisByDateAndClient" element={<RequireAuth><Report tittle="PCN Analysis by Date and Client" path="/report/transaction/analysisByDateAndClient" pcnFilter={false} vrmFilter={false} purchaseOps={false} lastDigitsFilter={false}/></RequireAuth>}/>
                                    <Route path="/financialDaily" element={<RequireAuth><Report tittle="Financial Daily" path="/report/transaction/financialDaily" pcnFilter={false} vrmFilter={false} purchaseOps={false} lastDigitsFilter={false}/></RequireAuth>}/>
                                    <Route path="/refundPCN" element={<RequireAuth><Refund/></RequireAuth>}/>
                                    <Route path="/refundDetail" element={<RequireAuth><RefundDetail/></RequireAuth>}/>
                                </Route>
                            </Routes>
                        </AuthProvider>
                    <p className="copyright-text">Connect Cashless Parking &copy; 2023</p>
                </div>
            </NotificationProvider>
    );
}

function Layout() {
    return (
        <div className="AppLayout">
            <NavbarComponent></NavbarComponent>
            <Outlet/>
        </div>
    );
}


export default App;
