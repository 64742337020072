import {Form} from "react-bootstrap";
import React from "react";

export interface FilterField {
    label:string;
    type: string;
    name?: string;
    htmlSize?:number;
    placeholder?: string;
    defaultChecked?: Boolean;
    value?: string| number;
    callback?: any;
    options?: FilterField[];
}

const RadioButton= (props:{id:any,filterField:FilterField})=>{

    return (
        <div key = {"div" + props.id + props.filterField.label} id = {props.id+10} className="flex-item max-content margin-left3">
            <Form.Label key = {"Form.Label" + props.id + props.filterField.label}>{props.filterField.label}:</Form.Label>
            <div key={`inline-radio`}>
                {props.filterField.options!.map((filter,idx)=>(
                    <Form.Check
                        key = {"Form.Check" + props.id + filter.label}
                        inline
                        label={filter.label}
                        name={filter.name}
                        // @ts-ignore
                        defaultChecked={filter.defaultChecked}
                        type='radio'
                        id={filter.label}
                        onChange={filter.callback}
                    />
                ))}
            </div>
        </div>
    )
}

const InputField = (props:{id:any,filterField:FilterField})=>{
    if (props.filterField.type == "number"){
        return (
            <div key={"div" + props.id} className="flex-item max-content">
                <Form.Label key={"Form.Label" + props.id + props.filterField.label}>{props.filterField.label}:</Form.Label>
                <Form.Control key={"Form.Control" +props.id + props.filterField.label}
                              type={props.filterField.type}
                              name={props.filterField.name}
                              placeholder={props.filterField.placeholder}
                              htmlSize={props.filterField.htmlSize}
                              value={props.filterField.value}
                              onChange={props.filterField.callback}
                />
            </div>
        )
    }else if (props.filterField.type == "text"){
        return (
            <div key={"div" + props.id} className="flex-item max-content">
                <Form.Label key={"Form.Label" + props.id + props.filterField.label}>{props.filterField.label}:</Form.Label>
                <Form.Control key={"Form.Control" +props.id + props.filterField.label}
                              type={props.filterField.type}
                              name={props.filterField.name}
                              placeholder={props.filterField.placeholder}
                              value={props.filterField.value}
                              onChange={props.filterField.callback}
                />
            </div>
        )
    }else {//radio button
        return (
            <RadioButton key={"RadioButton" + props.id} id={"RadioButton" + props.id}  filterField = {props.filterField} />
        )
    }
};

export const FilterFields = (props: {filterList:FilterField[]} )=>{

    if (props.filterList.length>0){
        return (
            <div key={945} className='div-row'>
                <div key={946} className="flex-container wrap">
                    {props.filterList.map((filter,idx)=>(
                        <InputField key={"InputField"+idx} id = {idx}  filterField = {filter} />
                    ))}
                </div>
            </div>
        );
    }else {
        return (
            <div></div>
        );
    }

}
