import * as React from 'react';
import {useState} from "react";

export type NotificationContextType = {
    success: (message: string) => void;
    error: (message: string) => void;
    clear: () => void;
    notification: string | undefined;
    notificationText: string | null;
    showNotification: boolean;

};

export const STATES = {
    SUCCESS: 'success',
    ERROR: 'danger',
};
export const NotificationContext = React.createContext<NotificationContextType | null>(null);

const NotificationProvider = ({children}: { children: React.ReactNode }) => {
        const [notification, setNotification] = useState<string | undefined>(undefined);
        const [notificationText, setNotificationText] = useState<string | null>(null);
        const [showNotification, setShowNotification] = useState(false);
        const success = (text: React.SetStateAction<string | null>) => {
            setNotificationText(text);
            setNotification(STATES.SUCCESS);
            setShowNotification(true);
        };
        const error = (text: React.SetStateAction<string | null>) => {
            setNotificationText(text);
            setNotification(STATES.ERROR);
            setShowNotification(true);
        };
        const clear = () => {
            setNotificationText('');
            setNotification('');
            setShowNotification(false);
        };


    return (
        <NotificationContext.Provider value={{ success, error, clear, notification, notificationText, showNotification}}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;