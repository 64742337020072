import {apiService} from "./ApiService";
import {Client} from "../models/Client";


const getAllClients = async (): Promise<any | undefined> => {
     return await apiService.run<Client[]>('/admin/clients').then((result)=>{
         if (!result) return [];
         return result;
     }).catch((e)=>{
         throw new Error('Error when calling /admin/clients ');
     });
}

const getClientsByUser = async (): Promise<any | undefined> => {
    return await apiService.run<Client[]>('/admin/clientsByUser').then((result)=>{
        if (!result) return [];
        return result;
    }).catch((e)=>{
        throw new Error('Error when calling /admin/clientsByUser ');
    });
}

export const clientService = {
    getAllClients,
    getClientsByUser
}
