import {CcpDateTime} from "../../models/ccp-date-time";
import {Button, Form} from "react-bootstrap";
import React, {useState} from "react";


export const DateTime = (props: {initialDate:CcpDateTime,dateChange:(data: any) =>void, label:string} )=>{

    const [date,setDate] = useState<CcpDateTime>(props.initialDate);

    const setNewDate = (newDate:CcpDateTime)=>{
        setDate(newDate);
        props.dateChange(newDate);
    }

    const dateFromChange = (event: { target: { name: any; value: any; }; }) => {
        let value = event.target.value;
        let newDate: CcpDateTime = CcpDateTime.fromString(value);
        newDate.setTimeStruct(date.getTime());
        setNewDate(newDate);
    }

    const dateHoursChange = (event: { target: { name: any; value: any; }; }) => {
        let value = Number(event.target.value);
        if ((value >= 0) && (value <= 23)) {
            let newDate:CcpDateTime = CcpDateTime.fromUnixTime(date.toMillis());
            newDate.setHour(value)
            setNewDate(newDate);
        }
    }

    const dateMinChange = (event: { target: { name: any; value: any; }; }) => {
        let value = event.target.value;
        if ((value >= 0) && (value <= 59)) {
            let newDate:CcpDateTime = CcpDateTime.fromUnixTime(date.toMillis());
            newDate.setMin(value)
            setNewDate(newDate);
        }
    }
    const dateSecsChange = (event: { target: { name: any; value: any; }; }) => {
        let value = event.target.value;
        if ((value >= 0) && (value <= 59)) {
            let newDate:CcpDateTime = CcpDateTime.fromUnixTime(date.toMillis());
            newDate.setSec(value)
            setNewDate(newDate);
        }
    }

    const startOfToday = () => {
        const newDate:CcpDateTime = CcpDateTime.startOfToday();
        setNewDate(newDate);
    }

    const endOfToday = () => {
        const newDate:CcpDateTime = CcpDateTime.endOfToday();
        setNewDate(newDate);
    }

    const startOfMonth = () => {
        const newDate:CcpDateTime = CcpDateTime.startOfMonth();
        setNewDate(newDate);
    }

    const startOfLastMonth = () => {
        const newDate:CcpDateTime = CcpDateTime.startOfLastMonth();
        setNewDate(newDate);
    }

    const endOfLastMonth = () => {
        const newDate:CcpDateTime = CcpDateTime.endOfLastMonth();
        setNewDate(newDate);
    }

    return (
        <div key={14} className='div-row'>
            <div key={15} className="flex-container">
                <label>{props.label}:</label>
            </div>
            <div key={16} className="flex-container">
                <div key={17} className="flex-item">
                    <input className='date-style' type="date" name={props.label}
                           value={CcpDateTime.toYYYYMMDD(date)}
                           onChange={dateFromChange}/>
                </div>
                <div className="flex-container-hour">
                    <div key={18} className="flex-item">
                        <Form.Control
                            type="number"
                            htmlSize={3}
                            name={props.label}
                            placeholder={props.label + " Hours"}
                            value={date.getTime().hour}
                            onChange={dateHoursChange}/>
                    </div>
                    <div key={19} className="flex-item">
                        <span className='margin3 vertical-middle'>:</span>
                    </div>
                    <div key={20} className="flex-item">
                        <Form.Control
                            type="number"
                            htmlSize={3}
                            name="dateFrom"
                            placeholder={props.label + " Minutes"}
                            value={date.getTime().minute}
                            onChange={dateMinChange}/>

                    </div>
                    <div key={21} className="flex-item">
                        <span className='margin3 vertical-middle'>:</span>
                    </div>
                    <div key={22} className="flex-item">
                        <Form.Control
                            type="number"
                            htmlSize={3}
                            name={props.label}
                            placeholder={props.label + " Seconds"}
                            value={date.getTime().second}
                            onChange={dateSecsChange}/>
                    </div>
                </div>
            </div>
            <div key={23} className="flex-container-buttons">
                <div className="flex-item">
                    <Button variant="outline-primary" onClick={() => startOfToday()}>Start of
                        Today</Button>
                </div>
                <div key={24} className="flex-item">
                    <Button variant="outline-primary" onClick={() => endOfToday()}>End of
                        Today</Button>
                </div>
                <div key={25} className="flex-item">
                    <Button variant="outline-primary" onClick={() => startOfMonth()}>Start of
                        this Month</Button>
                </div>
                <div key={26} className="flex-item">
                    <Button variant="outline-primary" onClick={() => startOfLastMonth()}>Start
                        Last Month</Button>
                </div>
                <div key={27} className="flex-item">
                    <Button variant="outline-primary" onClick={() => endOfLastMonth()}>End of
                        Last Month</Button>
                </div>
            </div>
        </div>
    )
}
