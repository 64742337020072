import React, {useEffect} from 'react';
import {useContext} from 'react';
import {NotificationContext} from "../../contexts/Notification";
import {Alert} from "react-bootstrap";
import './NotificationBar.css';
import _ from "lodash";

const NotificationBar = () => {
    const notificationCtx = useContext(NotificationContext);

    // useEffect(() => {
    //     _.delay(function () {
    //         notificationCtx?.clear();
    //     }, 4000);
    // }, [notificationCtx?.showNotification]);

    return (
        <div className="customNotificationBar">
            {notificationCtx?.showNotification &&
                <Alert show={notificationCtx.showNotification} variant={notificationCtx?.notification}
                       onClose={() => notificationCtx?.clear()} dismissible>
                    {notificationCtx.notificationText}
                </Alert>
            }
        </div>
    )
        ;
};
export default NotificationBar;