import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {userService} from "../../../services/UserService";
import {useLocation, useNavigate} from "react-router-dom";
import {UserData} from "../../../models/UserModel";
import {useAuth} from "../../../contexts/Auth";
import {authService} from "../../../services/AuthService";
import './Add.css';
import {NotificationContext} from "../../../contexts/Notification";

export const Add = () => {
    let auth = useAuth();
    const {state} = useLocation();
    let navigate = useNavigate();
    let userData = state?.user;
    let hasPermission = state?.permission;
    let isAdmin = userData?.isAdmin;
    const [isEdit, setIsEdit] = useState(state.user ? true : false);
    const [inputs, setInputs] = useState<UserData>({
        username: "",
        forename: "",
        surname: "",
        email: "",
        password: "",
        isAdmin: false,
        clientsIds: []
    });
    const [errorMessage, setErrorMessage] = useState('');
    const notificationCtx = useContext(NotificationContext);

    const [clientList, setClientList] = useState<any[]>([]);
    useEffect(() => {
        userService.getClientList().then((response) => {
            if (response.data) {
                setClientList(prev => {
                    return response.data.clients;
                });
            } else {
                setErrorMessage(response.message);
                console.log("response.message", response.message);
            }
        }).catch((e) => {
            console.error(e);
            notificationCtx?.error('Network error. Check internet connection or try again later.')
        });
    }, []);

    useEffect(() => {
        if (isEdit) {
            if (isAdmin || hasPermission) {
                userService.getUserClientsById(userData.id).then((response) => {
                    if (response.data) {
                        let temp = response.data.clients.map((val: { id: any; }) => val.id);
                        setInputs(() => ({
                            username: userData.username,
                            forename: userData.forename,
                            surname: userData.surname,
                            email: userData.email,
                            password: userData.password,
                            isAdmin: userData.isAdmin,
                            clientsIds: temp
                        }));
                    } else {
                        setErrorMessage(response.message);
                        console.log("response.message", response.message);
                    }
                }).catch((e) => {
                    console.error(e);
                    notificationCtx?.error('Network error. Check internet connection or try again later.')
                });
            } else {
                setInputs(() => ({
                    ...inputs,
                    username: userData.username,
                    forename: userData.forename,
                    surname: userData.surname,
                    email: userData.email
                }));
            }
        }
    }, []);

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (isEdit) {
            if (!hasPermission && !isAdmin) {
                userService.updateSelf(userData.id, inputs).then((response: any) => {
                    if (response.data) {
                        let token = authService.getAuthTokenFromStorage();
                        if(token){
                        auth.relogin(token).then((res) => {
                            if(res == 'ok'){
                                navigate("/home")
                            } else {
                                setErrorMessage(res);
                            }
                        });
                        }
                    } else {
                        setErrorMessage(response.message);
                        console.log("response.message", response.message);
                    }
                }).catch((e) => {
                    console.error(e);
                    notificationCtx?.error('Network error. Check internet connection or try again later.')
                });
            } else {
                userService.updateUser(userData.id, inputs).then((response: any) => {
                    if (response.data) {
                        navigate('/list');
                    } else {
                        setErrorMessage(response.message);
                        console.log("response.message", response.message);
                    }
                }).catch((e) => {
                    console.error(e);
                    notificationCtx?.error('Network error. Check internet connection or try again later.')
                });
            }
        } else {
            userService.addUser(inputs).then((response: any) => {
                if (response.data) {
                    navigate('/list');
                } else {
                    setErrorMessage(response.message);
                    console.log("response.message", response.message);
                }
            }).catch((e) => {
                console.error(e);
                notificationCtx?.error('Network error. Check internet connection or try again later.')
            });
        }
    }
    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleChangeCheckBox = (event: { target: { name: any; value: any; checked: any }; }) => {
        let temp: number[] = [];
        let clientValue = event.target.name;
        if (clientValue == 'isAdmin') {
            setInputs(values => ({...values, [clientValue]: event.target.checked}));
        } else {
            if (event.target.checked) {
                temp = inputs.clientsIds.concat(Number(clientValue));
                setInputs(() => ({...inputs, clientsIds: temp}));
            } else {
                temp = inputs.clientsIds.filter(val => val != clientValue);
                setInputs(() => ({...inputs, clientsIds: temp}));
            }
        }
    }

    return (
        <div className="add-wrapper">

            <Card className="add-main-card">
                <h1 className="add-main-title">
                    {isEdit && <p>Edit User</p>}
                    {!isEdit && <p>Add User</p>}
                </h1>
                <hr className="add-block-separator"/>
                <Card.Body>
                    <Form>
                        <div className='p-1 add-form-wrapper'>
                            <div className='add-form-div'>
                                <div className='p-2'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder={"Email"}
                                        value={inputs.email ?? ''}
                                        onChange={handleChange}/>
                                </div>
                                <div className='p-2'>
                                    <Form.Label>User Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        placeholder={"Username"}
                                        value={inputs.username ?? ''}
                                        onChange={handleChange}/>
                                </div>
                                <div className='p-2'>
                                    <Form.Label>User Forename</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="forename"
                                        placeholder={"User Forename"}
                                        value={inputs.forename ?? ''}
                                        onChange={handleChange}/>
                                </div>
                                <div className='p-2'>
                                    <Form.Label>User Surname</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="surname"
                                        placeholder={"User Surname"}
                                        value={inputs.surname ?? ''}
                                        onChange={handleChange}/>
                                </div>
                            </div>
                            <div className='add-form-div'>
                                <div className='p-2'>
                                    <Form.Label>Password*</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        placeholder={"New Password Required"}
                                        value={inputs.password ?? ''}
                                        onChange={handleChange}/>
                                </div>

                                {(() => {
                                        if (isAdmin || hasPermission) {
                                            return (
                                                <div>
                                                    <div className='p-2'>
                                                        <Form.Label>Client List*</Form.Label>
                                                        <div key={`inline-checkbox`} className="p-1">
                                                            {clientList.map((item, index) => (
                                                                <div
                                                                    key={index}><Form.Check
                                                                    inline
                                                                    label={item.name}
                                                                    name={item.id.toString()}
                                                                    type='checkbox'
                                                                    id={'inline-checkbox-'+index}
                                                                    onChange={handleChangeCheckBox}
                                                                    checked={inputs?.clientsIds.includes(item.id)}/>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className='p-2 add-single-check-box'>
                                                        <Form.Check
                                                            inline
                                                            label="Is Admin*"
                                                            name="isAdmin"
                                                            type='checkbox'
                                                            id={`inline-checkbox-1`}
                                                            onChange={handleChangeCheckBox}
                                                            checked={inputs.isAdmin}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                )()}
                            </div>
                        </div>
                        <p className="mandatory-message">*Mandatory Fields</p>
                        <hr className="add-bottom-block-separator"/>
                        {!isEdit && <Button onClick={handleSubmit} className="btn btn-primary">Add user</Button>}
                        {isEdit && <Button onClick={handleSubmit} className="btn btn-primary">Edit user</Button>}
                    </Form>
                </Card.Body>
                <p className="add-error-message">{errorMessage}</p>
            </Card>
        </div>
    );
}