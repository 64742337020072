import React from "react";
import newLogo from "../../assets/img/new_logo_white.png";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../contexts/Auth";
import {Button} from "react-bootstrap";

export const NavbarComponent = () => {

    let navigate = useNavigate();
    let auth = useAuth();
    let userForename = auth?.user?.username;

    return (
        <Navbar sticky="top" expand="lg" bg="dark" variant="dark" style={{width: '100%'}}>
            <Container fluid>
                <Navbar.Brand style={{justifyContent: "space-between", width: "100%", display: "flex"}}>
                    <div style={{display: "flex", marginLeft: 10}}>
                        <a className="navbar-brand">
                            <img style={{cursor: 'pointer'}} onClick={() => navigate("/home")} height={30}
                                 src={newLogo}/>
                        </a>
                        <Button style={{
                            alignSelf: "center", marginLeft: 10,
                            display: "flex",
                            paddingBlock: 5,
                            paddingInline: 10,
                            borderWidth: 1,
                            borderColor: "#17a2b8",
                            borderStyle: "solid",
                            borderRadius: 3,
                            background: "transparent",
                            color: "#17a2b8"
                        }}
                                onClick={() => navigate("/home")}>
                            Menu
                        </Button>
                    </div>
                    <div>
                        <NavDropdown style={{
                            display: "flex",
                            paddingBlock: 5,
                            paddingInline: 10,
                            borderWidth: 1,
                            borderColor: "#17a2b8",
                            borderStyle: "solid",
                            borderRadius: 3,
                            background: "transparent",
                            color: "#17a2b8"
                        }} title={userForename} id="collasible-nav-dropdown"
                                     drop={"start"}>
                            <NavDropdown.Item onClick={() => navigate('/add', {state: {user: auth?.user, permission: false}})}>Manage Account</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => {
                                auth.signout(() => navigate("/"));
                            }}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}