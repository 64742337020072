import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Form, OverlayTrigger, Popover} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Report.css';

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";


import BootstrapTable, {ColumnDescription, SizePerPageRendererOptions} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {MultiSelect} from "react-multi-select-component";
import {clientService} from "../../services/ClientService";
import {reportService} from "../../services/ReportService";
import useCopyToClipboard from "../../hooks/CopyToClipboard";
import {exportService} from "../../services/exportService";
import _ from 'lodash';
import {CcpDateTime} from "../../models/ccp-date-time";
import {NotificationContext} from "../../contexts/Notification";
import {Multiselect} from "multiselect-react-dropdown";
import {MultiSelectDropdown} from "../../components/multiselect-dropdown/MultiSelectDropdown";
import {DateTime} from "../../components/date-time/DateTime";
import {FilterField, FilterFields} from "../../components/filter-fields/FilterFields";

interface Inputs {
    dateFrom: CcpDateTime,
    dateTo: CcpDateTime,
    pcn: string,
    vrm: string,
    lastDigits: string,
    receiptId: string,
    clientsIds: Option[],
    successList: number[],
}

interface Option {
    value: any;
    label: string;
    key?: string;
    disabled?: boolean;
}

export const Report = (props: { tittle: string, path: string, pcnFilter: boolean, vrmFilter: boolean, purchaseOps: boolean, lastDigitsFilter: boolean,searchByReceiptId?:boolean,tabReport?:boolean }) => {
    const [value, copy] = useCopyToClipboard();
    const [show, setShow] = useState(false);
    const [tab, setTab] = useState<string>('default');
    const [clientList, setClientList] = useState<Option[]>([]);
    const [columnList, setColumnList] = useState<ColumnDescription[]>([]);
    const [reportData, setReportData] = useState<[]>([]);
    const notificationCtx = useContext(NotificationContext);
    const [inputs, setInputs] = useState<Inputs>({
        dateFrom: CcpDateTime.startOfMonth(),
        dateTo: CcpDateTime.now(),
        pcn: '',
        vrm: '',
        lastDigits: '',
        receiptId: '',
        clientsIds: [],
        successList: [0, 1]
    });


    let options: Option[] = [];

    const getFilterList = (pcnFilter: boolean, vrmFilter: boolean, purchaseOpsFilter: boolean, lastDigitsFilter: boolean, receiptIdFilter: boolean): FilterField[] =>{
        let list:  FilterField[] =[];

        const pcn:FilterField ={
            callback: handleChange,
            label: "PCN",
            name: "pcn",
            placeholder: "PCN" ,
            type: "text",
            value: inputs.pcn
        }

        const vrm:FilterField ={
            callback: handleChange,
            label: "VRM",
            name: "vrm",
            placeholder: "VRM" ,
            type: "text",
            value: inputs.vrm
        }

        const last4:FilterField ={
            callback: handleChange4Digits,
            label: "Last 4",
            name: "lastDigits",
            htmlSize: 6,
            placeholder: "Last 4" ,
            type: "number",
            value: inputs.lastDigits
        }

        const receiptId:FilterField ={
            callback: handleChange,
            label: "Receipt Id",
            name: "receiptId",
            placeholder: "Receipt Id" ,
            type: "text",
            value: inputs.receiptId
        }

        const radioFailure:FilterField ={
            callback: handleChangeCheckBox,
            label: "Failure",
            name: "radioGroup",
            defaultChecked:false,
            type: "radio",
        }

        const radioSuccess:FilterField ={
            callback: handleChangeCheckBox,
            label: "Success",
            name: "radioGroup",
            defaultChecked:false,
            type: "radio",
        }

        const radioBoth:FilterField ={
            callback: handleChangeCheckBox,
            label: "Both",
            name: "radioGroup",
            defaultChecked:true,
            type: "radio",
        }

        const radioOps:FilterField ={
            label: "Purchase Options",
            options: [],
            type: "radio",
        }

        if (pcnFilter){
            list.push(pcn);
        }

        if (vrmFilter){
            list.push(vrm);
        }

        if (lastDigitsFilter){
            list.push(last4);
        }

        if (receiptIdFilter){
            list.push(receiptId);
        }

        if (purchaseOpsFilter){
            radioOps.options?.push(radioFailure);
            radioOps.options?.push(radioSuccess);
            radioOps.options?.push(radioBoth);
            list.push(radioOps);
        }

        return list;
    }

    useEffect(() => {
        clientService.getClientsByUser().then((result: any) => {
            let clients: any;

            clients = result.data.clients;
            options = [];
            for (let i = 0; i < clients.length; i++) {
                let option: Option = new Option();
                option.value = clients[i].id;
                option.label = clients[i].name;
                options.push(option)
            }
            setClientList(options);
        }).catch((e) => {
            console.error(e);
            notificationCtx?.error('Network error. Check internet connection or try again later.')
        });

    }, []);

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const value = event.target.value.trim();
        setInputs(values => ({...values, [name]: value}))
    }

    const changeTab = (e:any):void =>{
        setTab(e);
    }

    const handleChange4Digits = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const value = event.target.value.trim();
        if (value.length<=4){
            setInputs(values => ({...values, [name]: value}))
        }
    }

    const dateFromChange2 = ( data: any) => {
        const name = 'dateFrom';
        let value = data;
        setInputs(values => ({...values, [name]: value}))
    }

    const dateToChange2 = ( data: any) => {
        const name = 'dateTo';
        let value = data;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleClientListChange = (options: Option[]) => {
        const name = 'clientsIds';
        setInputs(values => ({...values, [name]: options}));
    }

    const handleChangeCheckBox = (event: { target: { id: any; name: any; value: any; }; }) => {
        const name = event.target.id;

        if (name === 'Failure') {
            inputs.successList = [0];
        } else if (name === 'Success') {
            inputs.successList = [1];
        } else if (name === 'Both') {
            inputs.successList = [0, 1];
        }
        setInputs(values => ({...values, [name]: inputs.successList}));
    }

    const handleSubmit = (event: { preventDefault: () => void; },receiptId?:boolean) => {
        event.preventDefault();
        let path =  props.path;
        if (receiptId){
            path = path + '/receiptId';
        }
        reportService.runReport(inputs, path).then((response: any) => {
            if (response) {
                if (response.fields) {
                    setColumnList(createColumns(response.fields, response.data));
                }
                if (response.data) {
                    // @ts-ignore
                    setReportData(_.orderBy(response.data, [getDefaultOrder(response.fields)], ['asc']));
                }
            } else {
                //TODO Toast the message;
            }
        }).catch((e) => {
            console.error(e);
            notificationCtx?.error('Network error. Check internet connection or try again later.')
        });
    }

    const getDefaultOrder = (fields: any): any => {
        for (const [key, value] of Object.entries(fields)) {
            // @ts-ignore  the sortable field is for set up the default order of the table
            if (value.sortable) {
                return key;
            }
        }
    }

    const createColumns = (fields: any, rows: any): ColumnDescription[] => {
        let columns: ColumnDescription[] = [];
        let hasLabel: boolean = false;
        for (const [key, value] of Object.entries(fields)) {
            let entry: ColumnDescription = {
                dataField: key,
                text: key,
                sort: true, // this is to allow to sort by columns( by default all columns can be sorted)
            }

            // @ts-ignore
            if (value.format === 'DateTime') {
                entry.formatter = dateTimeFormatter
                // @ts-ignore
            } else if (value.format === 'Date') {
                entry.formatter = dateFormatter
                // @ts-ignore
            } else if (value.format === 'Integer') {
                entry.formatter = numberFormatter
            }

            // @ts-ignore
            if (value.aggregate != null) {
                // @ts-ignore
                if (value.aggregate === 'Label') {
                    entry.footer = 'Total:'
                    hasLabel = true;
                    // @ts-ignore
                } else if (value.aggregate === 'Total') {
                    //entry.footer = columnData => columnData.reduce((acc: any, item: any) => acc + item, 0)
                    const sum = _.sumBy(rows, key).toLocaleString();
                    entry.footer = sum;
                }
            }
            columns.push(entry);
        }

        // we need to put an empty footer so the table is complete without an empty cell
        if (hasLabel) {
            columns.forEach(column => {
                if (column.footer == null) {
                    column.footer = ' ';
                }
            });
        }
        return columns;
    }

    const sizePerPageRenderer = (rendererOptions: SizePerPageRendererOptions) => (
        <div key={"pagination"} className="btn-group" role="group">
            {   // @ts-ignore
                rendererOptions.options.map((option) => {
                    const isSelect = rendererOptions.currSizePerPage === `${option.page}`;
                    return (
                        <button
                            key={option.text}
                            type="button"
                            onClick={() => rendererOptions.onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-secondary' : 'btn-light'}`}
                        >
                            {option.text}
                        </button>
                    );
                })
            }
        </div>
    );

    const customPagination = {
        sizePerPageRenderer
    };

    const dateTimeFormatter = (cell: any, row: any) => {
        let value: string = new Date(cell).toLocaleString();
        return (
            <span>{value}</span>
        )
    }

    const dateFormatter = (cell: any, row: any) => {
        let value: string = new Date(cell).toLocaleDateString();
        return (
            <span>{value}</span>
        )
    }

    const numberFormatter = (cell: any, row: any) => {
        let value2 = cell.toLocaleString();
        return (
            <span>{value2}</span>
        )
    }

    const copyClipboard = () => {
        copy(exportService.copyClipboard(columnList, reportData)).then(r => {
            setShow(!show);
            _.delay(function () {
                setShow(false);
            }, 2000,);
        });
    }

    const downloadAsCsv = () => {
        exportService.downloadAsCsv(columnList, reportData);
    }

    const downloadAsXLSX = () => {
        exportService.downloadAsXLSX(columnList, reportData);
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <strong>Copied to Clipboard!</strong>
            </Popover.Body>
        </Popover>
    );

    return (
        <div key={3} className="menu">
            <Card key={4} className="mainCard">

                <h1 key={5} className="marginTop15">
                    {props.tittle}
                </h1>
                <hr key={6}/>
                <Card.Body key={7}>
                    {props.tabReport
                        ?(
                            <Tabs   id={"tab"}
                                    activeKey={tab}
                                    className="mb-3"
                                    onSelect={changeTab}>
                                <Tab eventKey="default" title="Search by PCN Info">
                                    <Form key={8}>
                                        <MultiSelectDropdown clientList={clientList} handleClientListChange={handleClientListChange} clientsIds={inputs.clientsIds}/>
                                        <DateTime key={77777} initialDate={inputs.dateFrom} dateChange={dateFromChange2} label={"Date From"} />
                                        <DateTime key={99999} initialDate={inputs.dateTo} dateChange={dateToChange2} label={"Date To"} />
                                        {
                                            inputs.dateTo.isBefore(inputs.dateFrom) &&
                                            <div key={42} className="flex-container">
                                                <div key={43} className="flex-item">
                                    <span key={44}
                                          className="red-text">* Date From has to be set before than Date To.</span>
                                                </div>
                                            </div>
                                        }

                                        <FilterFields key={88888} filterList={getFilterList(props.pcnFilter, props.vrmFilter, props.lastDigitsFilter, props.purchaseOps,false)} />

                                        {(inputs.dateTo.isBefore(inputs.dateFrom) || (inputs.clientsIds.length <= 0))
                                            ? <Button disabled onClick={handleSubmit} className="btn btn-primary">Submit</Button>
                                            : <Button onClick={handleSubmit} className="btn btn-primary">Submit</Button>
                                        }
                                        <hr/>
                                    </Form>
                                </Tab>
                                <Tab eventKey="receiptId" title="Search by Receipt Id">
                                    <FilterFields key={88888} filterList={getFilterList(false,false,false,false,true)} />
                                    {(inputs.receiptId.trim() == "")
                                        ? <Button disabled onClick={(e)=>handleSubmit(e,true)} className="btn btn-primary">Submit</Button>
                                        : <Button onClick={(e)=>handleSubmit(e,true)} className="btn btn-primary">Submit</Button>
                                    }
                                    <hr/>
                                </Tab>
                            </Tabs>
                        )
                        :(
                            <Form key={8}>
                                <MultiSelectDropdown clientList={clientList} handleClientListChange={handleClientListChange} clientsIds={inputs.clientsIds}/>
                                <DateTime key={77777} initialDate={inputs.dateFrom} dateChange={dateFromChange2} label={"Date From"} />
                                <DateTime key={99999} initialDate={inputs.dateTo} dateChange={dateToChange2} label={"Date To"} />
                                {
                                    inputs.dateTo.isBefore(inputs.dateFrom) &&
                                    <div key={42} className="flex-container">
                                        <div key={43} className="flex-item">
                                    <span key={44}
                                          className="red-text">* Date From has to be set before than Date To.</span>
                                        </div>
                                    </div>
                                }

                                <FilterFields key={88888} filterList={getFilterList(props.pcnFilter, props.vrmFilter, props.lastDigitsFilter, props.purchaseOps,false)} />

                                {((inputs.dateTo.isBefore(inputs.dateFrom)) || (inputs.clientsIds.length <= 0))
                                    ? <Button disabled onClick={handleSubmit} className="btn btn-primary">Submit</Button>
                                    : <Button onClick={handleSubmit} className="btn btn-primary">Submit</Button>
                                }
                                <hr/>
                            </Form>
                        )
                    }

                    <div key={50}>
                        <div key={51}>
                            {(() => {
                                if ((reportData.length > 0) && (columnList.length > 0)) {
                                    return (
                                        <div key={52}>
                                            <div key={53} className='div-row'>
                                                <OverlayTrigger show={show} trigger="click" placement="right"
                                                                overlay={popover}>
                                                    <Button variant="link" onClick={copyClipboard}>Copy to
                                                        Clipboard</Button>
                                                </OverlayTrigger>
                                                <Button variant="link" onClick={downloadAsCsv}>Download as CSV</Button>
                                                <Button variant="link" onClick={downloadAsXLSX}>Download as
                                                    Spreadsheet</Button>
                                            </div>
                                            <div key={54} className="div-horizontal">
                                                <BootstrapTable
                                                    key={55}
                                                    bootstrap4
                                                    striped
                                                    keyField="id"
                                                    data={reportData}
                                                    columns={columnList}
                                                    condensed={true}
                                                    pagination={paginationFactory(customPagination)}
                                                />
                                            </div>
                                        </div>
                                    )
                                } else if ((reportData.length === 0) && (columnList.length > 0)) {
                                    return (
                                        <h3 key={2} className="margin15">
                                            No results found.
                                        </h3>
                                    )
                                } else {
                                    return (
                                        <div key={1}></div>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
