import React from "react";
import {Card, ListGroup} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../contexts/Auth";
import './Home.css';

export const Home = () => {
    let navigate = useNavigate();
    let auth = useAuth();
    let isAdmin = auth?.user?.isAdmin;

    return (
        <div className="home-wrapper">

            <Card className="home-main-card">
                <Card.Body>
                    <div className="home-section-wrapper">
                        <Card className="home-section-card">
                            <Card.Body>
                                <Card.Title className="home-card-title">PCN Reports</Card.Title>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="home-section-item" onClick={() =>  navigate('/viewPCN')}>View PCN</ListGroup.Item>
                                    <ListGroup.Item className="home-section-item" onClick={() =>  navigate('/pcnAnalysisByDate')}>PCN Analysis by Date</ListGroup.Item>
                                    <ListGroup.Item className="home-section-item" onClick={() =>  navigate('/analysisByClient')}>PCN Analysis by Client</ListGroup.Item>
                                    <ListGroup.Item className="home-section-item" onClick={() =>  navigate('/analysisByDateAndClient')}>PCN Analysis by Date and Client</ListGroup.Item>
                                    <ListGroup.Item className="home-section-item" onClick={() =>  navigate('/financialDaily')}>Financial Daily</ListGroup.Item>

                                </ListGroup>
                            </Card.Body>
                        </Card>
                        <Card className="home-section-card">
                            <Card.Body>
                                <Card.Title className="home-card-title">PCN Refund</Card.Title>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="home-section-item" onClick={() =>  navigate('/refundPCN')}>Refund PCN</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                        {(() => {
                                if (isAdmin) {
                                    return (
                                        <Card className="home-section-card">
                                            <div>
                                                <Card.Body>
                                                    <Card.Title className="home-card-title">Management</Card.Title>
                                                    <ListGroup variant="flush">
                                                        <ListGroup.Item className="home-section-item"
                                                                        onClick={() => navigate('/list')}>Manage
                                                            User</ListGroup.Item>
                                                    </ListGroup>
                                                </Card.Body>
                                            </div>
                                        </Card>
                                    )
                                }
                            }
                        )()}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
