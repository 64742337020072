import {apiService} from "./ApiService";


const searchTransactions = async (inputs: any): Promise<any | undefined> => {
    const request: any = {
        startDate: inputs.startDate.prepare(),
        endDate: inputs.endDate.prepare(),
        pcnNumber: inputs.pcnNumber,
        vrm: inputs.vrm,
        receiptId: inputs.receiptId
    };

    return await apiService.run<any>('/transaction/search', request);
}

const refundTransaction = async (transactionId: number, amount: number): Promise<any | undefined> => {
    const request: any = {
        transactionId,
        amount
    };
    return await apiService.run<any>('/transaction/refund', request);
}

const searchRefund = async (transactionId: number): Promise<any | undefined> => {
    const request: any = {
        transactionId
    };

    return await apiService.run<any>('/transaction/searchRefund', request);
}

export const transactionService = {
    searchTransactions,
    refundTransaction,
    searchRefund
}
