const AUTH_COOKIE = 'ccp_auth';

let authToken: string;

const getAuthTokenFromStorage = (): string | null => {
    return localStorage.getItem(AUTH_COOKIE);
}

const setAuthTokenToStorage = (newAuthToken: string) => {
    if (!newAuthToken) {
        return;
    }
    authToken = newAuthToken;
    localStorage.setItem(AUTH_COOKIE, newAuthToken);
}

const removeAuthTokenFromStorage = () => {
    authToken = "";
    localStorage.removeItem(AUTH_COOKIE);
}

const isLoggedIn = (): boolean => {
    return authToken ? true : false;
}

const getAuthToken = (): string => {
    return authToken;
}

const onLogin = (newAuthToken: string) => {
    if (!newAuthToken) {
        return;
    }
    authToken = newAuthToken;
    localStorage.setItem(AUTH_COOKIE, authToken);
}

const logout = (callback: VoidFunction) => {
    authToken = '';
    localStorage.removeItem(AUTH_COOKIE);
}


export const authService = {
    getAuthTokenFromStorage,
    logout,
    onLogin,
    getAuthToken,
    isLoggedIn,
    setAuthTokenToStorage,
    removeAuthTokenFromStorage
};
