
interface NgbDateStruct {
    year: number;
    month: number;
    day: number;
}

interface NgbTimeStruct {
    hour: number;
    minute: number;
    second: number;
}

export class CcpDateTime {
    // Never null
    private date: NgbDateStruct;
    // Never null
    private time: NgbTimeStruct;

    constructor(year: number,
                month: number,
                day: number,
                hour: number = 0,
                minute: number = 0,
                second: number = 0) {
        this.date = {
            year: year,
            month: month,
            day: day
        };

        this.time = {
            hour: hour,
            minute: minute,
            second: second
        };
    }

    // Constructs a CcpDateTime from an array of numbers of length 3-6 of format [year, month, day, hours, minutes, seconds]
    // This is the one to use for java's LocalDateTime
    public static fromArray(arr: number[]) {
        if (!Array.isArray(arr)) return this.fromString(arr);
        return new CcpDateTime(
            arr[0],
            arr[1],
            arr[2],
            arr[3] || 0,
            arr[4] || 0,
            arr[5] || 0)
    }

    // Assuming format yyyy-mm-dd hh:mm:ss
    public static fromString(str: string) {
        const year = Number(str.substring(0, 4));
        const month = Number(str.substring(5, 7));
        const date = Number(str.substring(8, 10));

        if (str.length === 10) {
            // Date only
            return new CcpDateTime(year, month, date);
        }

        const hour = Number(str.substring(11, 13));
        const minute = Number(str.substring(14, 16));
        const second = Number(str.substring(17, 19));

        return new CcpDateTime(year, month, date, hour, minute, second);
    }

    public static fromUnixTime(millis: number | string) {
        millis = Number(millis);
        millis = Math.floor(millis);
        if (String(millis).length <= 10) {
            millis *= 1000;
        }
        const jsDate = new Date(millis);
        return new CcpDateTime(jsDate.getUTCFullYear(), jsDate.getUTCMonth() + 1, jsDate.getUTCDate(), jsDate.getUTCHours(), jsDate.getUTCMinutes(), jsDate.getUTCSeconds());
    }

    public static prepare(ccpDateTime: CcpDateTime) {
        return ccpDateTime.prepare();
    }

    //Returns a String of Month and year to 2 digits each (eg: xx/xx)
    public getMonthYearString(): string {
        let m = '0' + this.date.month;
        return m.substr(m.length-2) + '/' + this.date.year.toString().substr(2);
    }

    public clone(): CcpDateTime {
        return new CcpDateTime(this.date.year, this.date.month, this.date.day, this.time.hour, this.time.minute, this.time.second);
    }

    public cloneDate(): CcpDateTime {
        return new CcpDateTime(this.date.year, this.date.month, this.date.day);
    }

    private pad(n:any) {
        return (n < 10) ? ("0" + n) : n;
    }

    public prepareDate(): string {
        return `${this.date.year}-${this.pad(this.date.month)}-${this.pad(this.date.day)}`;
    }

    public prepareTime(): string {
        return `${this.pad(this.time.hour)}:${this.pad(this.time.minute)}:${this.pad(this.time.second)}`;
    }

    public prepareString(): string {
        return `${this.prepareDate()}T${this.prepareTime()}`;
    }

    public prepare() {
        return this.prepareString();
    }

    static now(): CcpDateTime {
        const now = new Date();
        return new CcpDateTime(now.getFullYear(), now.getMonth()+1 , now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
    }

    static startOfToday(): CcpDateTime {
        const now = new Date();
        return new CcpDateTime(now.getFullYear(), now.getMonth()+1, now.getDate(), 0, 0, 0);
    }

    static endOfToday(): CcpDateTime {
        const now = new Date();
        return new CcpDateTime(now.getFullYear(), now.getMonth()+1, now.getDate(), 23, 59, 59);
    }

    static startOfMonth(): CcpDateTime {
        const now = new Date();
        now.setDate(1);
        return new CcpDateTime(now.getFullYear(), now.getMonth()+1, now.getDate(), 0, 0, 0);
    }

    static endOfMonth(): CcpDateTime {
        const now = new Date();
        now.setDate(0);
        return new CcpDateTime(now.getFullYear(), now.getMonth()+1, now.getDate(), 23, 59, 59);
    }

    static startOfLastMonth(): CcpDateTime {
        const now = new Date();
        now.setDate(1);
        now.setMonth(now.getMonth() );
        return new CcpDateTime(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    }

    static endOfLastMonth(): CcpDateTime {
        const now = new Date();
        now.setDate(0);
        return new CcpDateTime(now.getFullYear(), now.getMonth()+1, now.getDate(), 23, 59, 59);
    }

    static monthAgoToday(endOfDay: boolean = false): CcpDateTime {
        const now = new Date();
        now.setDate(now.getDate() - 30);
        return new CcpDateTime(
            now.getFullYear(),
            now.getMonth() + 1,
            now.getDate(),
            endOfDay ? 23 : 0,
            endOfDay ? 59 : 0,
            endOfDay ? 59 : 0
        );
    }

    static isDateStructValid(date: NgbDateStruct) {
        return (date != null && date.year != null && date.month != null && date.day != null);
    }

    static isTimeStructValid(time: NgbTimeStruct) {
        return (time != null && time.hour != null && time.minute != null && time.second != null);
    }

    static  toYYYYMMDD (dateObj: CcpDateTime):string {
        let month = ('0' + (dateObj.date.month)).slice(-2);
        let date = ('0' + dateObj.date.day).slice(-2);
        let year = dateObj.date.year;
        let shortDate = year + '-' + month + '-' + date;
        return shortDate;
    }

    public isValid(): boolean {
        return CcpDateTime.isDateStructValid(this.date) && CcpDateTime.isTimeStructValid(this.time);
    }

    public setTo(newDateTime: CcpDateTime) {
        this.date = newDateTime.date;
        this.time = newDateTime.time;
    }

    public getDate(): NgbDateStruct {
        return this.date;
    }

    public setDate(year: number, month: number, day: number):CcpDateTime {
        this.date.year = year;
        this.date.month = month;
        this.date.day = day;
        return this;
    }

    public getTime(): NgbTimeStruct {
        return this.time;
    }

    public setTimeStruct(time:NgbTimeStruct) {
        this.time=time;
    }

    public setTime(hour: number, minute: number, second: number):CcpDateTime {
        this.time.hour = hour;
        this.time.minute = minute;
        this.time.second = second;
        return this;
    }

    public setHour(hour: number):CcpDateTime{
        this.time.hour = hour;
        return this;
    }

    public setMin(min:number):CcpDateTime{
        this.time.minute = min;
        return this;
    }

    public setSec(sec:number):CcpDateTime{
        this.time.second = sec;
        return this;
    }

    public toMillis(): number {
        if (!this.isValid()) {
            return 0;
        }

        // Construct CcpDateTime into a local JS Date
        const date = new Date(this.date.year, this.date.month - 1, this.date.day, this.time.hour, this.time.minute, this.time.second);

        // Adjust for TimeZone
        const adjustedDate =  new Date(date.valueOf() - date.getTimezoneOffset() * (60 * 1000));
        return adjustedDate.getTime();
    }

    public isBefore(time: CcpDateTime) {
        const timeM = time.toMillis();
        const testM = this.toMillis();

        return testM < timeM;
    }

    public isBetween(start: CcpDateTime, end: CcpDateTime) {
        const startM = start.toMillis();
        const endM = end.toMillis();
        const testM = this.toMillis();
        return testM >= startM && testM <= endM;
    }

    public isAfter(time: CcpDateTime) {
        const timeM = time.toMillis();
        const testM = this.toMillis();

        return testM > timeM;
    }

}
