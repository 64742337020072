import {MultiSelect} from "react-multi-select-component";
import React from "react";

import './MultiSelectDropdown.css';

interface Option {
    value: any;
    label: string;
    key?: string;
    disabled?: boolean;
}

export const MultiSelectDropdown = (props: {clientList:Option[],clientsIds:Option[],handleClientListChange:(options: Option[]) =>void}) => {

    return (
        <div key={9} className='div-row'>
            <div key={10} className="flex-container">
                <label>Client List:</label>
            </div>
            <div key={11} className="flex-container">
                <div key={12} className="flex-item">
                    <MultiSelect key={12}
                                 className='multi-select'
                                 options={props.clientList}
                                 value={props.clientsIds}
                                 onChange={props.handleClientListChange}
                                 labelledBy="Select"
                    />
                </div>
                {
                    (props.clientsIds.length <= 0) &&
                    <div key={13} className="flex-item">
                                        <span
                                            className="red-text"> * It is required to select at least one client.</span>
                    </div>
                }
            </div>
        </div>
    )
}

