import {ColumnDescription} from "react-bootstrap-table-next";
import {utils, WorkBook, WorkSheet, writeFileXLSX} from "xlsx";
import {saveAs} from "file-saver";


const toAoAReport = (columnList:ColumnDescription[],reportData:[]) => {
    let data: Array<Array<any>> = [];
    let i: number = 0;

    data[i] = columnList.map(c => c.text);

    reportData.forEach(row => {
        i++;
        data[i] = Object.values(row);
    })

    //todo implement this properly when added aggregatedrows
    let hasAggregateRow = false;
    if (hasAggregateRow) {
        i++;
    }
    return data;
}

const downloadAsXLSX = (columnList:ColumnDescription[],reportData:[]) => {
    let data: Array<Array<any>> = toAoAReport(columnList,reportData);

    /* generate worksheet */
    const ws: WorkSheet = utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    writeFileXLSX(wb, 'report.xlsx');

}

const downloadAsCsv = (columnList:ColumnDescription[],reportData:[]) => {
    let data: Array<Array<any>> = toAoAReport(columnList,reportData);
    let csv: string = '';
    let firstRow: boolean = true;

    let addCell = (data: any) => {
        data = String(data);
        data = data.replace('"', '""');
        data = '"' + data + '",';
        csv += data;
    };

    let addRow = () => {
        csv += '\n';
    };

    for (let row of data) {
        if (!firstRow) {
            addRow();
        } else {
            firstRow = false;
        }
        for (let cell of row) {
            addCell(cell);
        }
    }

    let blob = new Blob([csv], {type: "text/csv;charset=utf-8"});

    saveAs(blob, 'report.csv');
}

const copyClipboard = (columnList:ColumnDescription[],reportData:[]) => {
    let tsv: string = '';
    let data: Array<Array<any>> = toAoAReport(columnList,reportData);
    let firstRow: boolean = true;
    let firstCell: boolean = true;

    let addCell = (data: any) => {
        if (!firstCell) tsv += '\t';
        data = String(data);
        tsv += data;
        firstCell = false;
    };

    let addRow = () => {
        tsv += '\n';
        firstCell = true;
    };

    for (let row of data) {
        if (!firstRow) {
            addRow();
        } else {
            firstRow = false;
        }
        for (let cell of row) {
            addCell(cell);
        }
    }
    return (tsv);
}

export const exportService = {
    toAoAReport,
    downloadAsXLSX,
    downloadAsCsv,
    copyClipboard
}
