import React, {useContext, useEffect, useState} from "react";
import './Login.css';
import newLogo from '../../assets/img/new-logo.png';
import {Button, Form, InputGroup} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../contexts/Auth";
import {authService} from "../../services/AuthService";
import {NotificationContext} from "../../contexts/Notification";

export const Login = () => {
    let token = authService.getAuthTokenFromStorage();
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();
    let from = location.state?.from?.pathname || "/home";
    const [errorMessage, setErrorMessage] = useState('');
    const notificationCtx = useContext(NotificationContext);

    const [inputs, setInputs] = useState<{ username: string, password: string }>({
        username: "",
        password: ""
    });

    useEffect(() => {
        if (token) {
            auth.relogin(token).then((res) => {
                if(res == 'ok'){
                    navigate("/home")
                } else {
                    setErrorMessage(res);
                }
            }).catch((e) => {
                console.error(e);
                notificationCtx?.error('Network error. Check internet connection or try again later.')
            });;
        }
    }, []);

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        auth.signin(inputs.username, inputs.password, () => {
            navigate(from, {replace: true});
        }).then((res) => {
            if(res == 'ok'){
                navigate(from, {replace: true});
            } else {
                setErrorMessage(res);
            }
        }).catch((e) => {
            console.error(e);
            notificationCtx?.error('Network error. Check internet connection or try again later.')
        });
    }

    return (
        <div className="login-wrapper box-shadow">
            <img className="logo-login" src={newLogo}/>
            <form className="form-login" onSubmit={handleSubmit}>
                <p className="sign-in-label">Please sign in</p>
                <InputGroup className="mb-3">
                    <Form.Control className="input-login"
                                  type="text"
                                  name="username"
                                  placeholder={"Username"}
                                  value={inputs.username}
                                  onChange={handleChange}/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <Form.Control className="input-login"
                                  type="password"
                                  name="password"
                                  placeholder={"Password"}
                                  value={inputs.password}
                                  onChange={handleChange}/>
                </InputGroup>
                <Button onClick={handleSubmit} className="btn btn-primary">Sign in</Button>
            </form>
            <p style={{fontSize: 'small', color: "red", margin: 10}}>{errorMessage}</p>
        </div>
    )
}