import {GetUserRequest, LoginRequest} from "../models/Requests";
import {ClientResponse, LoginResponse} from "../models/Responses";
import {apiService} from "./ApiService";
import {authService} from "./AuthService";
import {User} from "../models/UserModel";

let loggedInUser: User;
// public permissions: BehaviorSubject<UserPermissions>;
// private branding: Branding;


const getUser = (): User => {
    // return this.storageService.get(AUTH_COOKIE);
    return User.new();
}

const login = async (username: string, password: string): Promise<any | undefined> => {
    const request: LoginRequest = {
        username: username,
        password: password
    };

    return await apiService.run<LoginResponse>('/admin/login', request, {noAuthRequired: true});

}

const reLogin = async (token: string): Promise<any | undefined> => {
    return await apiService.run<LoginResponse>('/admin/relogin', null, {specificAuthToken: token});
}

const getUserById = async (id: number): Promise<any | undefined> => {
    const request: GetUserRequest = {
        id
    };

    return await apiService.run<LoginResponse>('/admin/users/get', request);
}

const getAllUsers = async (): Promise<any | undefined> => {
    return await apiService.run<LoginResponse>('/admin/users');
}

const addUser = async (inputs: any): Promise<any | undefined> => {
    const request: any = {
        username: inputs.username,
        email: inputs.email,
        forename: inputs.forename,
        surname: inputs.surname,
        clientsIds: inputs.clientsIds,
        password: inputs.password,
        isAdmin: inputs.isAdmin
    };

    return await apiService.run<LoginResponse>('/admin/add', request);
}

const updateUser = async (id: any, inputs: any): Promise<any | undefined> => {
    const request: any = {
        id: id,
        username: inputs.username,
        email: inputs.email,
        forename: inputs.forename,
        surname: inputs.surname,
        clientsIds: inputs.clientsIds,
        password: inputs.password,
        isAdmin: inputs.isAdmin
    };

    return await apiService.run<LoginResponse>('/admin/update', request);
}

const updateSelf = async (id: any, inputs: any): Promise<any | undefined> => {
    const request: any = {
        id: id,
        username: inputs.username,
        email: inputs.email,
        forename: inputs.forename,
        surname: inputs.surname,
        password: inputs.password,
    };

    return await apiService.run<LoginResponse>('/admin/updateSelf', request);
}

const deleteUser = async (id: number): Promise<any | undefined> => {
    const request: any = {
        id
    };
    return await apiService.run<LoginResponse>('/admin/delete', request);
}

const getUserClientsById = async (id: number): Promise<any | undefined> => {
    const request: any = {
        id
    };
    return await apiService.run<ClientResponse[]>('/admin/user/get/clients', request);
}

const getClientList = async (): Promise<any | undefined> => {
    return await apiService.run<ClientResponse[]>('/admin/clients');
}



export const userService = {
    login,
    getUser,
    reLogin,
    getUserById,
    getAllUsers,
    addUser,
    deleteUser,
    getUserClientsById,
    updateUser,
    updateSelf,
    getClientList
};